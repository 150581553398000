import { Fragment, useContext } from "react";
import { useDispatch } from "react-redux";
import GeneralContext from "../../../components/GeneralContext";
import fatIconButtonFactory from "../utils/fatIconButtonFactory";
import {
    ArrowBack,
    Facebook,
    X,
    Mail,
    Link as LinkIcon,
} from "@churchofjesuschrist/eden-icons";
import { get } from "@churchofjesuschrist/universal-env";
import { showToast as showToastAction } from "../../../actions/notifications";
import {
    useSelectI18nStringById,
    useMountEffect,
} from "../../../../util/custom-hooks";
import Divider from "./Divider";
import analytics from "../../../../util/analytics";
import { closestElement } from "../../../../util/utils";
import {
    addHashFromVerse,
    dotToIdNotation,
    toContentUri,
} from "../../../../util/uri-utils";
import { buildContentUri } from "../../../actions/annotations";

const ArrowBackButton = fatIconButtonFactory(ArrowBack);
const FacebookButton = fatIconButtonFactory(Facebook);
const TwitterButton = fatIconButtonFactory(X);
const MailButton = fatIconButtonFactory(Mail);
const LinkButton = fatIconButtonFactory(LinkIcon);

const shareLookup = {
    FACEBOOK: (subject, body, url) =>
        `https://www.facebook.com/sharer/sharer.php?u=${url}&quote=${subject}%0A%0A${body}`,
    TWITTER: (subject, body, url) =>
        `https://twitter.com/share?url=${url}&text=${subject}%0A%0A${body}`,
    EMAIL: (subject, body, url) =>
        `mailto:?subject=${subject}&body=${subject}%0A%0A${body}%0A%0A${url}`,
};

export const Share = ({
    appUrl,
    changeView,
    lang,
    offsets,
    range,
    selectI18nStringById,
    showToast,
}) => {
    const fireShareEvent = (shareType, url) => {
        analytics.fireShareEvent({
            component: {
                info: {
                    channel: shareType.toLowerCase(),
                    link: url,
                },
            },
        });
    };

    const buildShareData = () => {
        let fragment = range?.cloneContents();
        let subject = buildReference(fragment);
        let body = cleanSelectedText(fragment);
        let contentUri = buildUri(offsets);
        let currentLangParameterString = "?lang=" + lang;
        contentUri = contentUri + currentLangParameterString;
        let dotNotationUrl = `${appUrl}${addHashFromVerse(contentUri)}`;
        let url = dotToIdNotation(dotNotationUrl);

        return { body, subject, url };
    };

    const shareSelection = (shareType) => {
        let { body, subject, url } = buildShareData();

        fireShareEvent(shareType, url);

        body = encodeURIComponent(body);
        subject = encodeURIComponent(subject);
        url = encodeURIComponent(url);

        window.open(shareLookup[shareType](subject, body, url));
    };

    const copySelection = () => {
        let { url } = buildShareData();

        navigator.clipboard.writeText(url);

        showToast({ type: "url-copied", disableAutoHide: false });
    };

    const cleanSelectedText = (fragment) => {
        if (!fragment) {
            let descriptionElement = document.querySelector(
                'meta[name="description"]'
            );
            fragment = new DocumentFragment();

            fragment.append(descriptionElement?.getAttribute("content") || "");
        }

        fragment
            .querySelectorAll("sup")
            .forEach((superscript) => superscript.remove());

        fragment
            .querySelectorAll("span[data-pointer-type]")
            .forEach((gutterIcon) => gutterIcon.remove());

        return (
            Array.from(
                fragment.querySelectorAll("p"),
                (paragraph) => paragraph.innerText
            ).join("\n\n") || fragment.textContent
        );
    };

    const buildReference = (fragment) => {
        let reference = document.title;
        let verses = Array.from(
            fragment?.querySelectorAll("[data-aid]") || [],
            (paragraph) =>
                document.querySelector(
                    `[data-aid="${paragraph.dataset.aid}"].verse .verse-number`
                )
        ).filter(Boolean);

        if (verses.length) {
            reference += `:${verses[0].innerText.trim()}`;

            if (verses.length > 1) {
                reference += `-${verses.pop().innerText.trim()}`;
            }
        } else if (range) {
            let verse = closestElement(range.startContainer).closest(
                "[data-aid].verse"
            );

            if (verse) {
                reference += `:${verse
                    .querySelector(".verse-number")
                    .innerText.trim()}`;
            }
        }

        return reference;
    };

    const buildUri = (offsets) => {
        if (offsets) {
            return buildContentUri(offsets);
        } else {
            if (window.location.pathname === "/") {
                return "";
            } else {
                return toContentUri(window.location.pathname);
            }
        }
    };

    useMountEffect(() => {
        if (navigator.userAgentData?.platform === "Windows") {
            return;
        } else if (navigator.canShare) {
            let { body: text, subject: title, url } = buildShareData();

            try {
                navigator.share({ url, title, text });
                fireShareEvent("native", url);
                changeView("DefaultToolbar");
            } catch (e) {
                console.error(e);
            }
        }
    });

    return (
        <Fragment>
            <ArrowBackButton
                onClick={() => {
                    changeView("DefaultToolbar");
                }}
                title={selectI18nStringById("annotationMenuBackText")}
                data-testid="share-back-button"
            />
            <Divider />
            <FacebookButton
                onClick={() => shareSelection("FACEBOOK")}
                title={selectI18nStringById("facebookLogoText")}
                data-testid="share-facebook-button"
            />
            <TwitterButton
                onClick={() => shareSelection("TWITTER")}
                title={selectI18nStringById("twitterLogoText")}
                data-testid="share-twitter-button"
            />
            <MailButton
                onClick={() => shareSelection("EMAIL")}
                title={selectI18nStringById("emailLogoText")}
                data-testid="share-email-button"
            />
            <LinkButton
                onClick={() => copySelection("COPY")}
                title={selectI18nStringById("copyLinkLogoText")}
                data-testid="share-copy-button"
            />
        </Fragment>
    );
};

const ShareContainer = ({ ...props }) => {
    const { APP_URL } = get();
    const selectI18nStringById = useSelectI18nStringById();
    const dispatch = useDispatch();
    const { lang } = useContext(GeneralContext);

    return (
        <Share
            {...props}
            appUrl={APP_URL}
            buildContentUri={(offsets) => dispatch(buildContentUri(offsets))}
            lang={lang}
            selectI18nStringById={selectI18nStringById}
            showToast={(payload) => dispatch(showToastAction(payload))}
        />
    );
};

export default ShareContainer;
