import { useEffect } from "react";
import styleContainer from "../../containers/styles";

const StyleDependentContent = ({
    addStyleSheet,
    children = null,
    currentLinks = [],
    links = [],
    loadedStyles,
}) => {
    useEffect(() => {
        links.forEach((link) => {
            if (
                !currentLinks.find(
                    (existingLink) => existingLink.href === link.href
                )
            ) {
                addStyleSheet(link);
            }
        });
    }, [currentLinks, links, addStyleSheet]);

    let loading = links.some((link) => !loadedStyles.includes(link.href));

    return loading ? null : children;
};

export default styleContainer(StyleDependentContent);
