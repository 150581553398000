import classes from "classnames";
import styles from "./ToggleOption.css";
import { MenuLabel } from "../../../MenuLabel";
import Toggle from "@churchofjesuschrist/eden-toggle";

const ToggleOption = ({
    className,
    description,
    disabled,
    onToggle,
    optionId,
    title,
    toggleTitle,
    value,
}) => {
    return (
        <MenuLabel
            align={"top"}
            className={classes(styles.toggleOption, className)}
            description={description}
            disabled={disabled}
            labelId={`${optionId}-toggle`}
            testId={optionId}
            title={title}
        >
            <Toggle
                className={styles.toggle}
                data-testid={`${optionId}-toggle`}
                disabled={disabled}
                id={`${optionId}-toggle`}
                on={value}
                onToggle={onToggle}
                small
                title={toggleTitle}
            />
        </MenuLabel>
    );
};

export default ToggleOption;
